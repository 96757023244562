<template>
  <!-- 个人中心 -->
  <div class="personalcenter">
    <div class="contain">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Personalcenter",
  components: {},
  data () {
    return {}
  },
  methods: {},
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .personalcenter {
      background: #f7f7f7;
      display: flex;
      justify-content: flex-start;
      align-items: center;
  
      .contain {
        width: 100%;
        margin: 0 auto;
        overflow: auto;
      }
    }
  }
@media screen and (min-width: 1200px) {
  .personalcenter {
      background: #f7f7f7;
      display: flex;
      justify-content: flex-start;
      align-items: center;
  
      .contain {
        width: 1640px;
        margin: 0 auto;
        overflow: auto;
      }
    }
}

</style>
